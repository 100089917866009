import React from "react";
import {
  SEO,
  MarkdownContent,
  Image,
  DynamicForm,
  BasicCarousel,
  componentIterator,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class BridalPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      azzdxobmhhdofiugkkuuwhymdhutedpcyakq,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          ogUrl={"https://www.juliajamessalon.com/bridal/"}
        />
        <Grid
          className={"custom-page-bridal custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"hnzfagkqhevvoslk"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column
              className={"naqufiiixymsxemy"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "bf96af1d-f922-41ce-b8e4-0b6b757c8361",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"scydecbguerdqaqd"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"ymalwuxcquqmdeai"}
              style={{ padding: 16 }}
              width={6}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "5bf68bab-b269-42b2-96fe-621123562ebe",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"ygkzleixvifqgczo"}
              style={{ padding: 16 }}
              width={8}
              textAlign={"justified"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "54272c88-9ad1-4468-ba6c-39729db62852",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"fgaqmcwjcqxovdjj"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"top"}
          >
            <Grid.Column
              className={"eitbfluaukchuvwz"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "75f4e0be-efec-4f09-879d-6e5eb7f4de51",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"qjrdkrmcnerunrft"}
              style={{ padding: 16 }}
              width={1}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"woumswjuyqhabocm"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "b07da9be-7a18-46ad-a164-82a7819812f5",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"fjktgkytyywszpnc"}
              style={{ padding: 16 }}
              width={1}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <div className={""} style={{}} />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"dzqjtgnzfjbxtrcj"}
              style={{ padding: 16 }}
              width={4}
              textAlign={"center"}
              verticalAlign={"top"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "bc67a685-db65-40a4-87d4-6cee0fa9ef93",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"qikieioibxwqionw"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"dgbmikpklaqweeqx"}
              style={{ padding: 16 }}
              width={10}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <DynamicForm
                  className={""}
                  style={{}}
                  businessId={2103}
                  businessInfoId={7251}
                  websiteId={8723}
                  locationId={2214}
                  componentConfigurationId={97387}
                  header={azzdxobmhhdofiugkkuuwhymdhutedpcyakq.data.header}
                  fields={
                    azzdxobmhhdofiugkkuuwhymdhutedpcyakq.data.dynamicFormFields
                  }
                  consentSettings={
                    azzdxobmhhdofiugkkuuwhymdhutedpcyakq.data.consentSettings
                  }
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"wswrmtjihrrbgaxw"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
            columns={1}
          >
            <Grid.Column
              className={"xauqolpelojxbanc"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "dd5803e3-802a-4cc6-ab81-5b20640f5d78",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"ldruwulfjvjnijhg"}
            style={{ padding: 16, background: "#B19B84" }}
            columns={1}
          >
            <Grid.Column className={"ynqxpcxotephsibz"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <BasicCarousel
                  className={"image-block"}
                  style={{}}
                  items={componentIterator({
                    iterator: getComponentFiles({
                      components: fishermanBusinessWebsitePage.components,
                      componentName: "BasicCarousel",
                      componentIdentifier:
                        "5dc01577-cc18-4cc7-8f05-34b254ee6081",
                    }),
                    component: <Image className={"image"} />,
                    propMap: { src: "__all__" },
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 29249 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
    azzdxobmhhdofiugkkuuwhymdhutedpcyakq: fishermanWebsiteComponent(
      fastId: { eq: "CustomForm" }
      componentIdentifier: { eq: "d6345617-6128-4b18-b7d5-c65a56456e9d" }
    ) {
      data {
        header
        dynamicFormFields {
          name
          label
          options {
            label
            value
          }
          required
          inputType
          widgetType
          placeholder
          sendEmailToSelectedLocation
        }
        consentSettings {
          show
          required
        }
      }
    }
  }
`;
